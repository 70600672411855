
import { SButton } from '@strana-artw/ui-kit';
import { toRefs } from 'vue';
import { storeToRefs } from 'pinia';
import Auth from '@/components/pages/login/Auth.vue';
import LoginSlider from '@/components/pages/login/LoginSlider.vue';
import { useScreenPlugin } from '@/app/plugins/vueScreen';
import { useAuthStore } from '@/store/authentication';

export default {
  name: 'LoginIndexPage',

  components: {
    LoginSlider,
    Auth,
    SButton
  },

  setup () {
    const { isMobile } = toRefs(useScreenPlugin());
    const { getLoginSlider } = useAuthStore();
    const { loginSliderItems } = storeToRefs(useAuthStore());

    return {
      isMobile,
      getLoginSlider,
      loginSliderItems
    };
  },

  data: () => ({
    isSliderShow: false,
    isSliderDisabled: false
  }),

  async fetch () {
    await this.getLoginSlider();
  },

  watch: {
    async isMobile () {
      if (!this.isMobile && this.isSliderShow) {
        await this.goIndex();
      }
    }
  },

  mounted () {
    this.isSliderShow = false;

    this.isSliderDisabled = JSON.parse(localStorage.getItem('disable_login_slider'));
  },

  methods: {
    async showSlider () {
      if (this.isMobile) {
        if (this.isSliderDisabled) {
          await this.goIndex();

          return;
        }
        this.isSliderShow = true;
      } else {
        await this.goIndex();
      }
    },

    async goIndex () {
      this.isSliderShow = false;
      await this.$router.push({
        name: 'index'
      });
    },

    async disableSlider () {
      try {
        localStorage.setItem('disable_login_slider', JSON.stringify(true));
      } catch (error) {
        console.log('🚀 ~ file: login/index.vue ~ disableSlider ~ error', error);
      }

      await this.goIndex();
    }
  }
};
