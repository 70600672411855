
import { SImageLazy } from '@strana-artw/ui-kit';
import BaseSwiperSlider from '@/components/common/BaseSwiperSlider.vue';
export default {
  name: 'LoginSlider',

  components: {
    SImageLazy
  },

  extends: BaseSwiperSlider,

  props: {
    items: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      autoplayDelay: 5000
    };
  },

  computed: {
    swiperOptions () {
      return {
        autoplay: {
          delay: this.autoplayDelay,
          disableOnInteraction: false
        },

        loop: true,
        speed: 500,
        effect: 'fade',
        slidesPerView: 1,
        fadeEffect: {
          crossFade: true
        },

        direction: 'horizontal',
        pagination: {
          el: this.$refs.pagination || false,
          type: 'bullets',
          clickable: true
        }
      };
    }
  }
};
