
import Swiper from 'swiper9/swiper-bundle.min';

export default {
  name: 'BaseSwiperSlider',

  data () {
    return {
      swiper: undefined
    };
  },

  updated () {
    if (this.swiper) {
      this.$nextTick(() => {
        this.swiper.update();
      });
    }
  },

  beforeDestroy () {
    if (this.swiper) {
      setTimeout(() => {
        this.swiper.destroy();
      }, 0);
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.createSwiper(this.swiperOptions);
    });
  },

  methods: {
    createSwiper (options = {}) {
      const swiperEl = this.$refs.swiper;

      if (swiperEl) {
        this.swiper = new Swiper(swiperEl, options);
      }
    }
  }
};
